footer{
    .copyright{
        @extend .fs_14;
        line-height: 1.7rem;
        color: $gray_solid;
        background: $light_black;
        height: 14rem;
        @extend .d_flex;
        @extend .align_items_end;
        @extend .justify_content_center;
        padding: 3rem 0;
        font-family: montserrat_medium;
        font-weight: 500;
    }
}