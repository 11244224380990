.clinics{
    width: 100%;
    height: 100%;
    .table_wrapper{
        height: calc(100% - 75px);
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        .pagination-container{
            align-self: flex-end;
        }
    }
    .heading_content{
        @extend .justify_content_between;
        .form_group{
            @extend .position_relative;
            padding: 4px 5px 4px 0;
            margin: 0;
            .form_control{
                max-width: 310px !important;
                width: 100% !important;
                min-width: 310px;
                padding-left: 3.3rem;
                background-color: #FFFFFF;
                padding: 9px 20px 10px 32px;
                display: block;
                min-height: auto !important;
                @extend .radius_20;
                letter-spacing: 0.7px;
                @extend .comman_input_scss;
                border-width: 2px;
                @media (max-width: 768px) {
                    min-width: unset;
                }
            }
            .serch_icon{
                width: 1.9rem;
                height: 1.9rem;
                @extend .position_absolute;
                top: 13px;
                left: .7rem;
                @extend .cursor_pointer;
                img{
                    width: 1.9rem;
                    @extend .img_object_center;
                }
            }
        }
        .add_clinics{
            padding: 5px 0 4px 5px;  
        }
    }
    .add_clinics{
        .btn{
            padding: 11px 26px;  
            @extend .montserrat_regular;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 1.5px;
            color: #FFFFFF;
            background-color: #495568;
        }
    }
    table.table.clinic_data_list .icons {
        line-height: 1;
        display: inline-block !important;
        vertical-align: baseline;
        span.actions_icon {
            display: inline-block !important;
            font-size: 12px;
            vertical-align: top;
        }
    }
    .invite_main{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        span{
            display: inline-block;
            padding: 0 10px 5px 0;
        }
    }
    span.filter_dropdown.Status{
        cursor: pointer;
        &.filter{
            padding-left: 8px;
            cursor: pointer;
            display: inline-block;
            img{
                vertical-align: middle;
            }
        }
    }
    .physician{
        .filter-drop-status{
            top: 40px;
        } 
    }
    .filter-drop-status{
        position: absolute;
        min-width: unset;
        background: #FDFDFD;
        border-radius: 3px;
        padding: 8px 6px 20px; 
        box-shadow: 0 0 2.5rem rgb(0 0 0 / 15%);
        z-index: 9;
        max-width: 124px;
        width: 100%;
        .drop_down_list.list_unstyled{
            margin: 0;
            h4{
                font-weight: 600;
                font-size: 10px;
                line-height: 12px;
                color: #000000;
                margin: 0;
                text-align: left;
                text-transform: capitalize;
                font-family: montserrat_bold;
                padding: 0 5px;
                padding-bottom: 10px;
            }
            .heading_row{
                display: flex;
                padding-bottom: 10px;
                h4{
                    font-weight: 600;
                    font-size: 10px;
                    line-height: 12px;
                    color: #000000;
                    margin: 0;
                    text-align: left;
                    text-transform: capitalize;
                    font-family: montserrat_semibold;
                    padding: 0 5px;
                    &:first-child{
                        padding-left: 0;
                    }
                    &.all, &.none{
                        font-weight: 600;
                        font-family: montserrat_bold;
                    }
                    &.none{
                        color: #C4C4C4;
                    }
                }
            }
            .status_checkbox{
                display: flex;
                align-items: center;
                &:last-child{
                    margin: 0;
                }
                & > [type=checkbox]{
                    height: 10px;
                    width: 10px;
                }
                span{
                    height: 10px;
                    width: 10px;
                    font-weight: 500;
                    border: unset;
                    background: #E3E3E3;
                    &::before{
                        content: "";
                        position: absolute;
                        display: none;
                    }
                    &::after{
                        content: "";
                        position: absolute;
                        display: none;
                    }
                }
                & > [type="checkbox"]:checked + span::before{
                    transform: unset;
                    background: #8C8C8C;
                    height: 100%;
                    width: 100%;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    display: block;
                }
                & > [type="checkbox"]:checked + span::after{
                    display: block;
                    background-image: url(../images/checkbox-icon.svg) !important;
                    background-repeat: no-repeat !important;
                    background-size: contain !important;
                    background: #8C8C8C;
                    height: 6px;
                    width: 9px;
                    left: 1px;
                    top: 2px;
                    right: 0;
                    bottom: 0;
                    transform: unset;
                  }
                label{
                    @extend .montserrat_regular;
                    font-size: 10px;
                    line-height: 12px;
                    color: #000000;
                    margin-left: 7px;
                }
            }
        }
    }
}
input.form_control.search_input {
    font-weight: 600;
    // color: #C4C4C4;
    // letter-spacing: 0.9px;
}
.add-clinic_block{
    padding: 36px 80px;
    @media (max-width: 1300px) {
        padding: 36px 20px;
    }
    @media (max-width: 991px) {
        flex-direction: column-reverse;
    }
 
}
.profile_main{
    padding-top: 26px;
    @include width(200px);

    @media (max-width: 1024px) {
        padding-left: 20px;
        @include width(220px);
    }
    @media (max-width: 991px) {
        padding-left: 0;
        @include width(200px);
        margin: 0 auto 20px;
    }
    .logo-notice{
        font-family: montserrat_medium;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.3125px;
        color: #595959;
    }
    .user_profile_pic{
        img.clinic-logo_img{
            height: 77px;
            width: 200px;
            border: 1.5px solid #DEDEDE;
            box-sizing: border-box;
            border-radius: 10px;
        }
        .addnew{
            bottom: -9px;
            right: -14px;
        }
    }
}
.clinic-addText{
    display: block;
    @include width(680px);
    padding: 0 20px 0 0;
    @media (max-width: 991px) {
      padding: 0;
    }
    &.edit-clinic_block{
        max-width: 580px;
    }
    .main_card{
        width: 100%;
        display: block;
        .form_group, .form_row{
            max-width: 437px;
        }
        .main_form_group{
            position: relative;
            display: flex;
            align-items: center;
            @media (max-width: 1150px) {
                flex-direction: column-reverse;
                align-items: unset;
            }
           
        }
    }
    .contact_user_name{
        max-width: 437px;
        width: 100%;
    }
    .card{
        &:not(:first-child){
            max-width: 514px;
            width: 100%;
        }
    }

    .skip_for_now_btn{
        padding: 12px 0 0 56px;
        @media (max-width: 1150px) {
            padding: 0 0 5px 0;
        }
        span{
            width: 100%;
            font-family: 'montserrat_regular';
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.3125px;
            text-align: left;
        }
    }
    .billing_period_card{
        padding: 0;
        .form_group{
            &:first-child{
                padding: 12px 0 0 25px;
                @media (max-width: 991px) {
                    padding-left: 15px;
                }
            }
        }
    }
}
.billing_card {
    flex-wrap: wrap;
    padding: 16px 0 10px 25px !important;
    label.billing_label {
        font-family: 'montserrat_regular';
        font-weight: 600;
        padding-right: 10px;
    }
    .form_group {
        margin: 0;
        label{
            font-weight: 400;
            color: rgba(0, 0, 0, 0.6);
        }
    }
}
.fill{
    font-family: 'montserrat_regular';
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3125px;
    color: #595959;
}
.clinic{
    .title{
        padding-left: 25px;
    }
}
.page_wrapper input, 
.ant-modal-root input,
.clinic textarea,
.ant-modal-root textarea,
.clinic input,
.clinic select,
 .ant-modal-root select{
  @extend .comman_input_scss;
}

button.invite{
    display: inline-block;
    min-width: unset;
    padding: 5px 10px;
    @extend .montserrat_regular;
    font-weight: 600;
    font-size: 10px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    letter-spacing: 0.4px;
}
.edit_clinic_block{
    .form_heading {
        padding: 0 !important;
    }
    .edit_clinic {
        .sub_comman_card{
            @media (max-width: 600px) {
                width: 100% ;
            }
        }
        form{
            @include width(600px);
            margin: 0 auto;
        }
    }
}
.status_head{
    position: relative;
}
.clinics td{
    vertical-align: top !important;
}
.comman_input_scss{
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3125px;
    color: #3e4a58;
    font-family: montserrat_medium;
    font-weight: 500;
    box-sizing: border-box;
    &::placeholder{
        font-family: montserrat_medium;
        font-weight: 500;
    }
}
.physician{
    thead tr td{
        padding: 13px 10px 20px !important;
    }
}
select.form_control {
    padding-right: 32px !important;
}
select[name='years_of_experiance']{
    padding-right: 26px!important;
    padding-left: 10px !important;
}

.hs_comman_modal_block.update-clinic_main {
    display: flex;
    flex-direction: row-reverse;
    padding: 0px 40px 20px 20px;
    .update-clinic_form{
        width: calc(100% - 200px);
        padding-left: 50px;
    }
    @media (max-width: 768px) {
        &{
            flex-direction: column-reverse;
            padding: 0px 20px;
            .update-clinic_form{
                width: 100%;
                padding-left: 0;
            }
        }
    }
}