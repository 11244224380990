.sidebar{
    width: 25rem;
    background: $light_blue;
    min-height: calc(100vh - 10rem);
    ul{
        margin: 0;
        li{
            padding: 28px 36px;
            @extend .fs_14;
            line-height: 2rem;
            @extend .fw_600;
            @extend .text_uppercase;
            color: $black;
            @extend .cursor_pointer;   
            &:hover{
                background: $dark_blue;
                color: $white;
            } 
            &.active{
                background: $dark_blue;
                color: $white;
            }
        }
    }
}
